import React, { useState, useEffect } from "react";
import { service } from './service';
import { useHistory } from 'react-router-dom';
import "./normalize.css";
import "./basic-formpage.css";
const ManageDevice = () => {
	const history = useHistory();
	const [apiResponse, setApiResponse] = useState({ data: [] });
	const [subItems, setSubItems] = useState([]);
	let path = window.location.pathname;
	path = path.length == 1 ? '/home' : path;
	useEffect(() => {
		let singleObj = [];
		service.manageDeviceAccess().then(response => {
			if (response.success === true) {
				let temp = [];
				
				response.data.map((item, index) => {
					let subItem = (
							<li className = "new">
								<hr></hr>
								<h3 className="activityUesn manageText">{item.device}</h3>
								<div className="activityAccess manageText">
									<div>{item.location}
									</div>
									<div className="activityDate manageText">
										{item.last_used}
									</div>
								</div>
								<hr></hr>
							</li>
					);
					temp.push(subItem);
				
				});
				setSubItems(temp)
			}
		})

	}, []);
	const onBack = (e) => {
		history.push('/account');
	}
	return (
		<div id="appMountPoint" style={{backgroundColor:'#f0f3f5',height:"1200px"}}>
			<div className="netflix-sans-font-loaded">
				<div lang="en-IN" className="accountLayout" dir="ltr">
					<div className="bd">
						<div className="responsive-account-container" >
							<div className="manageDeviceWrapper">
								<h5 className="device-text">Recent device streaming activity</h5>
								<h6 className="sub-heading">The most recently used devices and locations on your account.</h6>
								<ul className="structural retable" id="recentDeviceId">
									{subItems}
								</ul>

								<button className="manageDevice" type="button" autoComplete="off" tabIndex="0" style={{width: '100px',backgroundColor:'rgb(20, 138, 183)'}}
									data-uia="" onClick={onBack}>Done</button>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ManageDevice;
