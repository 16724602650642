import React, { useState, useEffect } from "react";
import Footer from "../Basic/Footer";
import { Redirect } from "react-router-dom";
import Header from "../Basic/Header";
import { Link, useHistory, useLocation } from "react-router-dom";
import SubscriptionContainer from "./SubscriptionContainer";
import { service } from "../../network/service";
import { loadStripe } from "@stripe/stripe-js";
import { ToastsContainer, ToastsStore } from "react-toasts";
import "./Payment.css";
import Modal from "react-modal";
import mastercard from "../../images/mastercard.png";
import visa from "../../images/visa.png";
import american from "../../images/american.png";
import paypal from "../../images/payp.png";
import { faLess } from "@fortawesome/free-brands-svg-icons";

// const stripePromise = loadStripe(
//   "pk_test_51JXVIJGTFp8Ff1xtRm1NktNHwOVmkxllYTpculsVzO5yAvZW8ehWutiS1L805eFvEjapPOSYb6i2xl72cGa1uaP600aNh9mtg3"
// );
const stripePromise = loadStripe(
  "pk_live_51JXVIJGTFp8Ff1xtNHFtWWNGDwT4QFaZOMmFZFU85flHP1xafmjUVi44hidGzXoF6qxLDdSyfS0opzV9nn5AV2Ik00Krodbphc"
);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "50%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Payment = (state) => {
  const history = useHistory();
  const isLoggedIn = service.getCookie("isLoggedIn");
  const [androidData, setAndroidData] = useState(null);
  const [isAndroid, setIsAndroid] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [paypalLink, setPaypalLink] = useState("");
  const [subscription, setSubscription] = useState([]);
  // const [openModal, setOpenModal] = useState(false);
  // const [showCouponInput, setShowCouponInput] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [applyCoupon, setApplyCoupon] = useState("");
  const [Invalid, setInvalid] = useState(false);
  const [notExist, setnotExist] = useState(false);
  const [success, setSuccess] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(false);
  const [isCouponEnabled, setIsCouponEnabled] = useState(false);
  const [isStripeClick, setIsStripeClick] = useState(false);
  const [couponNotValid, setCouponNotValid] = useState(false);
  console.log("state",state)
  useEffect(() => {
    window.scrollTo(0, 0);
    if (state.location.state) {
      setAndroidData(state.location.state.androidData);
      setPaymentData(state.location.state.paymentData);
      setIsAndroid(state.location.state.isAndroid);
      localStorage.setItem("deviceType", "web");
      // if (isAndroid) {
      //   localStorage.setItem("isAndroid", isAndroid);
      //   localStorage.setItem("deviceType", "android-web");
      // }
      if (state.location.state.isAndroid) {
        localStorage.setItem("isAndroid", state.location.state.isAndroid);
        localStorage.setItem("deviceType", "android-web");
      }
      let tempData = state.location.state.paymentData;
      let deviceType = localStorage.getItem("deviceType");
      let userId = service.getCookie("userId");
      let countryCode = service.getCookie("country_code");
      service.setCookie("subId", tempData.publisher_subscription_id, 10);
      localStorage.setItem("selectedSubId", tempData.publisher_subscription_id);
      var user_data = {
        transaction_type: 1,
        subscription_id: tempData.publisher_subscription_id,
        uid: userId,
        amount: tempData.price,
        pubid: 50031,
        country_code: countryCode,
        device_type: deviceType,
      };
      var user_details = encodeURIComponent(JSON.stringify(user_data));
      let link = `https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=${tempData.paypal_keyword}&custom=${user_details}&discount=10&currency_code=USD&upload=1`;
      // let link = `https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=${tempData.paypal_keyword}&custom=${user_details}&discount=10&currency_code=USD&upload=1`;
      setPaypalLink(link);
    } else if (isLoggedIn == "true") {
      history.push("/home");
    }

    var singleObj = [];
    let userId = service.getCookie("userId");
    service.userSubscription(userId).then((response) => {
      if (response.success == true) {
        if (response.forcibleLogout === true) {
          alert(
            "Sorry, You’ve reached the maximum Device limit. Please log in again!"
          );
          service.logoutAll(userId).then((res) => {
            setTimeout(() => {
              redirectToLogin();
            }, 1000);
          });
        } else if (response.session_expired === true) {
          ToastsStore.warning("Sorry! Session Has Expired");
          redirectToLogin();
        } else {
          var data = response.data;
          data.map((item, index) => {
            singleObj.push(item);
          });
          setSubscription(singleObj);
        }
      }
    });
  }, []);
  useEffect(() => {
    localStorage.removeItem("fromVideoplayer");
  }, []);

  const redirectToLogin = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    service.eraseCookie("showId");
    service.eraseCookie("isScreeningUser");
    // dispatch({ type: "LOGOUT" });
    // setMouseHover(false);
    service.eraseCookie("userName");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
    service.eraseCookie("subscriptionId");
    setTimeout(() => {
      let isAndroid = localStorage.getItem("isAndroid");
      if (isAndroid == true || isAndroid == "true") {
        window.location.href = 
        "https://chicanohollywood.tv/webviewfailed";
        // "https://staging.justwatchme.tv/webviewfailed";
      } else {
        window.location.href = "/signin";
      }
    }, 500);
  };
  const handleCoupon = (e) => {
    // const {name, value} = e.target;
    setCoupon(e.target.value);
  };
  const applyCoupons = async () => {
    service.couponCode(coupon).then((response) => {
      // After success payment, when calling updateTransaction API, pass the coupon_id
      if (response.data) {
        if (response.data.coupon_id) {
          let coupon_id = response.data.coupon_id;
          localStorage.setItem("coupon_id", coupon_id);
          setSuccess(true);
          setIsCouponEnabled(true);
          setnotExist(false);
          setInvalid(false);
          setCouponNotValid(false);
          setDiscountedPrice(true);
          setPaymentResponse(response.data.discounted_price);

          let selected_amount = response.data.discounted_price;
          localStorage.setItem("selected_amount", selected_amount);
        }
      } else if (response.message == "Coupon does not exist") {
        setnotExist(true);
        setSuccess(false);
        setInvalid(false);
        setCouponNotValid(false);
        setDiscountedPrice(false);
      }else if (response.message == "Coupon is not valid for this subscription.") {
        setnotExist(false);
        setSuccess(false);
        setInvalid(false);
        setCouponNotValid(true);
        setDiscountedPrice(false);
      } 
       else {
        setInvalid(true);
        setSuccess(false);
        setnotExist(false);
        setCouponNotValid(false);
        setDiscountedPrice(false);
      }
    });
  };
  const onClickPaypalButton=(link)=>{
    if(coupon){
      alert("Coupon Code is not applicable for PayPal!")
    }
    else{
      window.open(link)
    }
    }
  const handleClick = async (event) => {
    setIsStripeClick(true);
    localStorage.setItem(
      "selectedSubId",
      paymentData.publisher_subscription_id
    );

    // localStorage.setItem("selectedAmount", paymentData.discounted_price);
    // localStorage.setItem("selectedAmount", paymentData.price););
    let coupon_id = localStorage.getItem("coupon_id");
    let session;
    if(isCouponEnabled == true){
      session = await service.stripeSession(
       paymentData.publisher_subscription_id,
       coupon, coupon_id
     );
   } else {
     localStorage.removeItem("coupon_id");
     localStorage.setItem("selected_amount", paymentData.price);
      session = await service.stripeSession(
       paymentData.publisher_subscription_id,
     );
   }
   const stripe = await stripePromise;
   const result = await stripe.redirectToCheckout({
     sessionId: session.id,
   });
    // const session = await service.stripeSession(
    //   paymentData.publisher_subscription_id
    // );
    // console.log("paymentData.subscription_id", paymentData.subscription_id);
    // const stripe = await stripePromise;

    // const result = await stripe.redirectToCheckout({
    //   sessionId: session.id,
    // });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };
  if (!isLoggedIn || isLoggedIn == "false") {
    return <Redirect push to="/signin" />;
  } else
    return (
      <div className="pageWrapper searchPageMain">
        <div className="topContainer">
          <div className="homepageWrapper menuCloseJS closeMenuWrapper">
            <div className="container">
              <p className="text-head" style={{marginTop:"7%"}}>Payment Gateway</p>
              <div>
                <p
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: "20px",
                    paddingLeft: "1%",
                  }}
                >
                  {" "}
                  Coupon Code :{" "}
                </p>
                <input
                  className="coupon_input"
                  type="text"
                  disabled={isCouponEnabled}
                  value={coupon}
                  style={{color:"white"}}
                  onChange={handleCoupon}
                />
                <button
                  onClick={applyCoupons}
                  style={{ height: "40px", width: "90px", fontSize: "15px" }}
                  type="submit"
                  disabled={isCouponEnabled}
                >
                  Apply
                </button>

                {success && (
                  <p style={{ color: "green" }}>
                    Coupon Code applied successfully
                  </p>
                )}
                {discountedPrice && (
                  <p style={{ color: "white" }}>
                    Grand Total : {paymentResponse}
                  </p>
                )}
                {/* <p style={{color:"white"}}>AMT:{paymentResponse.discountedPrices} </p>  */}
                {/* {console.log("++654++++",paymentResponse.discountedPrices)} */}
                {notExist && (
                  <p style={{ color: "red" }}>Coupon Code does not exit</p>
                )}
                {Invalid && (
                  <p style={{ color: "red" }}>
                    Entered coupon code has expired
                  </p>
                )}
              </div>
              {/* <fieldset className="myFieldSet" style={{marginTop:"5%"}}>
                <legend className="payment-box">
                  Select a payment gateway :
                </legend>
                <img
                  className="cards1"
                  style={{ marginLeft: "15px" }}
                  src={require("../../images/stripe.jpeg")}
                  onClick={handleClick}
                ></img>
                <a href={paypalLink}>
                  <img
                    className="cards"
                    src={require("../../images/paypal2.png")}
                  ></img>
                </a>
              </fieldset> */}
               <fieldset className="myFieldSet">
                <legend className="payment-box">
                  Select a payment gateway
                </legend>
                <img
                  className="cards1"
                  style={{ marginLeft: "15px" }}
                  src={mastercard}
                  onClick={handleClick}
                ></img>
                <img
                  className="cards1"
                  style={{ marginLeft: "15px" }}
                  src={visa}
                  onClick={handleClick}
                ></img>
                <img
                  className="cards1"
                  style={{ marginLeft: "15px" }}
                  src={american}
                  onClick={handleClick}
                ></img>
                <a 
                onClick={()=>onClickPaypalButton(paypalLink)}
                   >
                  <img className="cards1" src={paypal}></img>
                </a>
              </fieldset>
              <SubscriptionContainer param={subscription} />
            </div>
            <ToastsContainer store={ToastsStore} />
          </div>
        </div>
      </div>
    );
};
export default Payment;
