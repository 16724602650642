import $ from "jquery";
$(document).ready(function () {
   var heightAdjustment = 0;
  if (
    navigator.userAgent.search("Safari") >= 0 &&
    navigator.userAgent.search("Chrome") < 0
  ) {
    heightAdjustment=200;
  }
  $(window).scroll(function () {
    var leftSectionHeight = $(".vp3Section.movieInfo").height() - heightAdjustment;
    var windowScroll = $(window).scrollTop();
console.log("heightAdjustment", heightAdjustment);
    if (windowScroll >= leftSectionHeight) {
      $(".videoPageContainer ._2xXnB").css("position", "absolute");
      $(".videoPageContainer ._2xXnB").css("top", leftSectionHeight + "px");
    } else if (windowScroll < leftSectionHeight) {
      $(".videoPageContainer ._2xXnB").css("position", "fixed");
      $(".videoPageContainer ._2xXnB").css("top", "100px");
    }
  });
});
