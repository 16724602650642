import React, { useState, useEffect } from "react";
import $ from "jquery";
import "./aboutUsStyle.css";
const AboutUs = () => {
  window.scrollTo(0, 0);
  useEffect(() => {
    $(".menuItemContainer").addClass("menuClose");
  }, []);
  return (
    <div
      style={{
        fontSize: "16px",
        height: "585px",
        backgroundColor: "black",
        // backgroundColor: "#ff8d00e3",
      }}
    >
      <div className="pageWrapper">
        <div className="topContainer">
          <div className="menuCloseJS closeMenuWrapper">
            <div id="content" className="site-content">
              <div id="primary" className="content-area">
                <main
                  id="main"
                  className="site-main"
                  style={{ marginTop: "15rem", textAlign: "center" }}
                >
                  <article
                    id="post-39"
                    className="post-39 page type-page status-publish hentry"
                  >
                    <div className="entry-content">
                      <div className="vc_row wpb_row vc_row-fluid  bb_custom_1584031025503">
                        <div className="wpb_column vc_column_container vc_col-sm-12">
                          <div className="vc_column-inner">
                            <div className="wpb_wrapper">
                              <div className=" fantastic-header ml-3">
                                <div
                                  id="main-bg"
                                  className="main-bg"
                                  style={{ top: "0px" }}
                                ></div>
                                <div className="header-content">
                                  <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-10">
                                      <h1
                                        className="fade-in-text"
                                        style={{
                                          letterSpacing: "2px",
                                          lineHeight: "2",
                                          animation: "fadein2 1s",
                                          fontSize: "2em",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Chicano Hollywood
                                      </h1>{" "}
                                      <p
                                        className="fade-in-text"
                                        style={{
                                          letterSpacing: "2px",
                                          lineHeight: "2",
                                          fontSize: "1.3em",
                                        }}
                                      >
                                        Chicano Hollywood is the first Latino owned streaming service in the nation. In these beginning stages of our growth we ask you to grow with us! Our commitment to you is to create movies, TV shows, documentaries, animations, musical videos in our voice. Enjoy our content and get ready for more!.{" "}
                                        <a style={{ color: "#fff" }}> </a>
                                      </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
