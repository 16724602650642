import React, { useState, useEffect } from "react";
import "./QuestionAnswer.css";
const QuestionAswerSection = () => {

  
  return (
    <div className="lpWhiteSection">
      <div className="container lpMainContainer" >
        <div className="row lpFlexWrapper">
          <div className="col col-12 col-md-8">
            <h2 className="H1s" >Frequently Asked Questions</h2>
          </div>
        </div>
        <div className="row lpFlexWrapper" >
          <div className="col col-12 col-md-6">
            <div className="wrapper">
              <div className="half">
                <div className="tab">
                  <input  className="inputsl" id="tab-one" type="checkbox" name="tabs" />
                  <label for="tab-one"  style={{color:"black"}}>What is Chicano Hollywood?</label>
                  <div className="tab-content">
                    <p> {" "}
                            Chicano Hollywood is a movement birthed on the set
                            of the feature film, "Horchata with Oat Milk". On
                            that set a bunch of Chicano filmmakers created a
                            story about the adjustments and effects of
                            gentrification with the theme of "whether good or
                            bad change happens". In similar fashion that story
                            epitomizes the current social standing of Chicanos
                            nationwide, including in Hollywood.{" "}</p>
                  </div>
                </div>
              </div>
              <div className="half">
                <div className="tab">
                  <input className="inputsl" id="tab-two" type="checkbox" name="tabs" />
                  <label for="tab-two"  style={{color:"black"}}>About Us</label>
                  <div className="tab-content">
                    <p>  {" "}
                            Chicano story tellers are growing in prominence, standing
                    and influence as Producers, Directors, DP's, Crew and
                    especially Talent. We are becoming a voice that reflects the
                    majority of the English speaking Latinos of any decent here
                    in the United States.{" "}</p>
                  </div>
                </div>
              </div>
              <div className="half">
                <div className="tab">
                  <input  className="inputsl" id="tab-three" type="checkbox" name="tabs" />
                  <label for="tab-three" style={{color:"black"}}> Our Goal?</label>
                  <div className="tab-content">
                    <p> {" "}
                            This website and social media accounts will be dedicated to
                    "Shouting Out" Chicanos and any Latinos that are making
                    their mark in this town of broken dreams. The goal is to
                    create a large platform so that we can produce our stories
                    in our voice for all audiences without anyones permission or
                    interference.{" "}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuestionAswerSection;
