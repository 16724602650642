import React, { useState, useEffect } from "react";
import { service } from "./network/service";
import { Link, useHistory } from "react-router-dom";
import "./App.css";
import Layouts from "./Layouts/routes";
import Fingerprint2 from "fingerprintjs2";
import { getSessionId } from "./Utils/utils";
import { useSelector, useDispatch } from "react-redux";
// import ClipLoader from "react-spinners/ClipLoader";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  let urlParams = new URLSearchParams(window.location.search);
  let androidToken = urlParams.get("antkn");
  if (androidToken) {
    localStorage.removeItem("userId");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
  }

  if (window.requestIdleCallback) {
    requestIdleCallback(function () {
      Fingerprint2.get(function (components) {
        var values = components.map(function (component) {
          return component.value;
        });
        var murmur = Fingerprint2.x64hash128(values.join(""), 31);
        // localStorage.setItem("deviceId", murmur);
        if (!localStorage.getItem("deviceId")) {
          localStorage.setItem("deviceId", murmur);
        }
        console.log("murmur1", murmur);
        service.getLocation().then((response) => {
          if (response && response.data) {
            let currentLocation = {};
            service.setCookie("country_code", response.data.countryCode, 30);
            service.setCookie("ipaddress", response.data.query, 30);
            localStorage.setItem("ipaddress", response.data.query);
            currentLocation["country_code"] = response.data.countryCode;
            currentLocation["country_name"] = response.data.country;
            currentLocation["city"] = response.data.city;
            currentLocation["latitude"] = response.data.lat;
            currentLocation["longitude"] = response.data.lon;
            currentLocation["IPv4"] = response.data.query;
            currentLocation["state"] = response.data.region;
            localStorage.setItem(
              "currentLocation",
              JSON.stringify(currentLocation)
            );
          }
        });
      });
    });
  } else {
    setTimeout(function () {
      Fingerprint2.get(function (components) {
        var values = components.map(function (component) {
          return component.value;
        });
        var murmur = Fingerprint2.x64hash128(values.join(""), 31);
        // localStorage.setItem("deviceId", murmur);
        if (!localStorage.getItem("deviceId")) {
          localStorage.setItem("deviceId", murmur);
        }
        console.log("murmur2", murmur);
        service.getLocation().then((response) => {
          if (response && response.data) {
            let currentLocation = {};
            service.setCookie("country_code", response.data.countryCode, 30);
            service.setCookie("ipaddress", response.data.query, 30);
            localStorage.setItem("ipaddress", response.data.query);
            currentLocation["country_code"] = response.data.countryCode;
            currentLocation["country_name"] = response.data.country;
            currentLocation["city"] = response.data.city;
            currentLocation["latitude"] = response.data.lat;
            currentLocation["longitude"] = response.data.lon;
            currentLocation["IPv4"] = response.data.query;
            currentLocation["state"] = response.data.region;
            localStorage.setItem(
              "currentLocation",
              JSON.stringify(currentLocation)
            );
          }
        });
      });
    });
  }

  async function fetchData() {
    await service
      .getLocation()
      .then((response) => {
        if (response && response.data) {
          let currentLocation = {};
          service.setCookie("country_code", response.data.countryCode, 30);
          service.setCookie("ipaddress", response.data.query, 30);
          localStorage.setItem("ipaddress", response.data.query);
          currentLocation["country_code"] = response.data.countryCode;
          currentLocation["country_name"] = response.data.country;
          currentLocation["city"] = response.data.city;
          currentLocation["latitude"] = response.data.lat;
          currentLocation["longitude"] = response.data.lon;
          currentLocation["IPv4"] = response.data.query;
          currentLocation["state"] = response.data.region;
          localStorage.setItem(
            "currentLocation",
            JSON.stringify(currentLocation)
          );

          if (
            service.getCookie("userId") != null &&
            service.getCookie("guestUserId") != service.getCookie("userId")
          )
            service.analytics().then((response) => {});
        }
      })
      .catch((error) => {
        if (
          service.getCookie("userId") != null &&
          service.getCookie("guestUserId") != service.getCookie("userId")
        )
          service.analytics().then((response) => {});
      });
  }

  // useEffect(() => {
  //   var currentURL = new URL(window.location.href);
  //   var key = currentURL.searchParams.get("key");
  //   if (!isAuthenticated) {
  //     if (service.getCookie("userId") == null) {
  //       if (localStorage.getItem("deviceId")) {
  //         service.getGuestUser().then((response) => {
  //           if (response) {
  //             service.authenticate().then((response) => {
  //               if (response.token) {
  //                 setIsAuthenticated(true);
  //               }
  //             });
  //           }
  //         });
  //       } else {
  //         Fingerprint2.get(function (components) {
  //           var values = components.map(function (component) {
  //             return component.value;
  //           });
  //           var murmur = Fingerprint2.x64hash128(values.join(""), 31);
  //           // localStorage.setItem("deviceId", murmur);
  //           if (!localStorage.getItem("deviceId")) {
  //             localStorage.setItem("deviceId", murmur);
  //           }
  //           service.getLocation().then((response) => {
  //             if (response && response.data) {
  //               let currentLocation = {};
  //               service.setCookie(
  //                 "country_code",
  //                 response.data.countryCode,
  //                 30
  //               );
  //               service.setCookie("ipaddress", response.data.query, 30);
  //               localStorage.setItem("ipaddress", response.data.query);
  //               currentLocation["country_code"] = response.data.countryCode;
  //               currentLocation["country_name"] = response.data.country;
  //               currentLocation["city"] = response.data.city;
  //               currentLocation["latitude"] = response.data.lat;
  //               currentLocation["longitude"] = response.data.lon;
  //               currentLocation["IPv4"] = response.data.query;
  //               currentLocation["state"] = response.data.region;
  //               localStorage.setItem(
  //                 "currentLocation",
  //                 JSON.stringify(currentLocation)
  //               );
  //               service.getGuestUser().then((response) => {
  //                 if (response) {
  //                   service.authenticate().then((response) => {
  //                     if (response.token) {
  //                       setIsAuthenticated(true);
  //                     }
  //                   });
  //                 }
  //               });
  //             }
  //           });
  //         });
  //       }
  //     } else {
  //       service.authenticate().then((response) => {
  //         if (response.token) {
  //           setIsAuthenticated(true);
  //         }
  //       });
  //     }
  //     if (localStorage.getItem("currentLocation") == null) {
  //       fetchData();
  //     }
  //     console.log("murmur3", localStorage.getItem("deviceId"));
  //     getSessionId();
  //   }
  // }, []);
  useEffect(() => {
    if (!isAuthenticated) {
      if (service.getCookie("userId") == null) {
        if (localStorage.getItem("deviceId")) {
          service.getGeoInfo().then((response) => {
            service.getGuestUser().then((response) => {
              if (response) {
                service.authenticate().then((response) => {
                  if (response.token) {
                    setIsAuthenticated(true);
                  }
                });
              }
            });
          });
        } else {
          Fingerprint2.get(function (components) {
            var values = components.map(function (component) {
              return component.value;
            });
            var murmur = Fingerprint2.x64hash128(values.join(""), 31);
            localStorage.setItem("deviceId", murmur);
            service.getGeoInfo().then((response) => {
              service.getGuestUser().then((response) => {
                if (response) {
                  service.authenticate().then((response) => {
                    if (response.token) {
                      setIsAuthenticated(true);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        if (localStorage.getItem("deviceId")) {
          service.getGeoInfo().then((response) => {
            service.authenticate().then((response) => {
              if (response.token) {
                setIsAuthenticated(true);
              }
              getSessionId();
              if (
                !localStorage.getItem("deviceAnalytics") &&
                service.getCookie("userId") != null &&
                service.getCookie("guestUserId") != service.getCookie("userId")
              ) {
                service.analytics().then((response) => {
                  localStorage.setItem("deviceAnalytics", true);
                });
              }

              if (
                !sessionStorage.getItem("applaunch") &&
                localStorage.getItem("isLoggedIn") == "true"
              ) {
                service.applaunchEvent("POP01").then((response) => {});
              }
            });
          });
        } else {
          Fingerprint2.get(function (components) {
            var values = components.map(function (component) {
              return component.value;
            });
            var murmur = Fingerprint2.x64hash128(values.join(""), 31);
            localStorage.setItem("deviceId", murmur);

            service.getGeoInfo().then((response) => {
              service.authenticate().then((response) => {
                if (response.token) {
                  setIsAuthenticated(true);
                }
                getSessionId();
                if (
                  !localStorage.getItem("deviceAnalytics") &&
                  service.getCookie("userId") != null &&
                  service.getCookie("guestUserId") !=
                    service.getCookie("userId")
                ) {
                  service.analytics().then((response) => {
                    localStorage.setItem("deviceAnalytics", true);
                  });
                }
                if (
                  !sessionStorage.getItem("applaunch") &&
                  localStorage.getItem("isLoggedIn") == "true"
                ) {
                  service.applaunchEvent("POP01").then((response) => {});
                }
              });
            });
          });
        }
      }
    }
  }, []);
  return (
    <div className="App" style={{ paddingTop: "0" }}>
      {isAuthenticated && <Layouts />}
    </div>
  );
};
export default App;


