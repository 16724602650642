import React, { useState, useEffect } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Slider from "react-slick";
import "react-alice-carousel/lib/alice-carousel.css";
import SupportedDeviceSection from "./SupportedDeviceSection";
import QuestionAswerSection from "./QuestionAswerSection";
import { deviceDetect } from "../../Utils/utils";
import { service } from "../../network/service";
import main from '../../images/landing/main.jpg';
import mobileimg from "../../images/ch.jpg";
import Header from "../Basic/Header";

var landingImages = [
  "https://gizmeon.s.llnwi.net/vod/chicanohollywood-static-files/banner.png"
];
var landingImagesMobile = ["https://gizmeon.s.llnwi.net/vod/ChicanoHollywood/MainM.jpg"];
  
 

const Landing = () => {
  const history = useHistory();
  localStorage.setItem("location", "/");
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  let userId = service.getCookie("userId");
  if (isLoggedIn === "true" && userId) {
    return <Redirect to="/home" />;
  }
  const [isDesktop, setIsDesktop] = useState(deviceDetect());
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <div>
      {isDesktop === false ? (
        <div className="pageWrapper searchPageMain">
          <div className="topContainer">
            <div className="landingPageWrapper closeMenuWrapper">
              <Slider {...settings}>
                {landingImagesMobile &&
                  landingImagesMobile.map((item, index) => {
                    return (
                      <div key={index}>
                         <div
                          className="mobileBanner"
                          style={{
                            backgroundImage: `url(${"https://gizmeon.s.llnwi.net/vod/ChicanoHollywood/MainM.jpg"})`,
                            // backgroundImage:{mobileimg},
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "100%",
                          }}
                        > 
                          <div className="lpBannerwrapper">
                            <div className="Xd2tG"></div>
                            <div className="container lpMainContainer" >
                              <div className="row lpSectionWrapper">
                                <div
                                  className="col col-12 col-md-12 lpFlexCenter"
                                  
                                  style={{
                                    paddingTop: "147%",
                                    paddingLeft: "13%",
                                  }}
                                >
                                  <Link to="/home">
                                    <button className="button buttonLarge   bigButton center">
                                      <div
                                        className="buttonBg rounderbutton"
                                        style={{
                                          background: "red",
                                          opacity: "0.7",
                                        }}
                                      ></div>
                                      <div className="buttonContent">
                                        Watch Now for Free
                                      </div>
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
              <div className="lpWhiteSection">
               
                <SupportedDeviceSection />
                <QuestionAswerSection />
               
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="pageWrapper searchPageMain">
          <div className="topContainer">
            <div className="landingPageWrapper closeMenuWrapper">
              <Slider {...settings}>
                {landingImages &&
                  landingImages.map((item, index) => {
                    return (
                      <div key={index}>
                        <div
                          className="lpBannerSection"
                          style={{
                            backgroundImage: `url(${"https://gizmeon.s.llnwi.net/vod/chicanohollywood-static-files/banner.png"})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "100%",
                          }}
                        >
                        
                          <div className="lpBannerwrapper">
                            <div className="Xd2tG"></div>
                            <div className="container lpMainContainer">
                             
                              <div className="row lpSectionWrapper">
                                <div className="col col-4"></div>
                                {localStorage.getItem("isLoggedIn") ? (
                                  <div
                                    className="col col-4"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginBottom: "65px",
                                    }}
                                  >
                                    <Link to="/home">
                                      <button className="button buttonLarge _2SyTX bigButton center">
                                        <div
                                          className="buttonBg rounderbutton"
                                          style={{
                                            background: "red",
                                            opacity: "0.7",
                                          }}
                                        ></div>
                                        <div className="buttonContent">
                                          Watch Now for Free
                                        </div>
                                      </button>
                                    </Link>
                                  </div>
                                ) : (
                                  <div
                                    className="col col-4"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginBottom: "65px",
                                    }}
                                  >
                                    <Link to="/home">
                                      <button className="button buttonLarge _2SyTX bigButton center">
                                        <div
                                          className="buttonBg rounderbutton"
                                          style={{
                                            background: "red",
                                            opacity: "0.7",
                                          }}
                                        ></div>
                                        <div className="buttonContent">
                                          Watch Now for Free
                                        </div>
                                      </button>
                                    </Link>
                                  </div>
                                )}
                                <div className="col col-4"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
              <div className="lpWhiteSection">
              
                <SupportedDeviceSection />

                <QuestionAswerSection />
              
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Landing;
